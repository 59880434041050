import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase/app'
import 'firebase/auth'
import axios from 'axios'

Vue.use(Vuex)

let baseURL = "https://api.prod.christiandate.kr"

if (process.env.NODE_ENV === 'test') {
  baseURL = "https://api.test.christiandate.kr"
} else if (process.env.NODE_ENV === 'development') {
  baseURL = "http://localhost:3000"
}

const store = new Vuex.Store({
  state: {
    baseURL: baseURL,
    currentUser: {},
    refCount: 0,
    isLoading: false,
    currentRequestIP: '::1',
    currentRequestTime: 0,
    datasetsRequest: new Promise(() => {}),
    isDatasetsRequestCalled: false,
    usersReviewGenderFilter: localStorage.getItem('usersReviewGenderFilter') || 'all',
    usersReviewChurchFilter: localStorage.getItem('usersReviewChurchFilter') || 'all',
    profileConfigs: [
      {
        category: "nickname",
        isCollapsable: false,
        rejectionTemplates: [
          { value: "타인에게 불쾌감을 줄 수 있는 닉네임은 사용이 불가합니다.", text: "#1" },
          { value: "개인연락처를 포함하는 닉네임은 사용이 불가합니다.", text: "#2" }
        ]
      },
      {
        category: "fullname",
        isCollapsable: false,
        rejectionTemplates: []
      },
      {
        category: "birthday",
        isCollapsable: false,
        rejectionTemplates: []
      },
      {
        category: "gender",
        isCollapsable: false,
        rejectionTemplates: []
      },
      {
        category: "church_name",
        isCollapsable: false,
        rejectionTemplates: [
          { value: "출석교회 정보 (교회명, 주소, 담임목사 성함)를 정확히 입력해주세요.", text: "#1" },
          { value: "국내 거주 당시 출석교회 정보를 적어주세요.", text: "#2" },
          { value: "입력정보에 대한 검증을 위해 교회명, 주소, 담임목사 성함이 잘 보이도록 주보를 촬영 후 고객센터(cs@ChristianDate.kr)로 보내주세요.", text: "#3" }
        ]
      },
      {
        category: "church_location",
        isCollapsable: false,
        rejectionTemplates: [
          { value: "출석교회 정보 (교회명, 주소, 담임목사 성함)를 정확히 입력해주세요.", text: "#1" },
          { value: "국내 거주 당시 출석교회 정보를 적어주세요.", text: "#2" },
          { value: "입력정보에 대한 검증을 위해 교회명, 주소, 담임목사 성함이 잘 보이도록 주보를 촬영 후 고객센터(cs@ChristianDate.kr)로 보내주세요.", text: "#3" }
        ]
      },
      {
        category: "pastor_name",
        isCollapsable: false,
        rejectionTemplates: [
          { value: "출석교회 정보 (교회명, 주소, 담임목사 성함)를 정확히 입력해주세요.", text: "#1" },
          { value: "국내 거주 당시 출석교회 정보를 적어주세요.", text: "#2" },
          { value: "입력정보에 대한 검증을 위해 교회명, 주소, 담임목사 성함이 잘 보이도록 주보를 촬영 후 고객센터(cs@ChristianDate.kr)로 보내주세요.", text: "#3" }
        ]
      },
      {
        category: "faith",
        isCollapsable: true,
        rejectionTemplates: [
          { value: "항목의 성격에 맞는 내용을 정확히 입력해주세요. (하단 예시 참조)", text: "#1" },
          { value: "현재 해외 거주 중인 경우 원활한 소개가 어렵습니다.\n소개되는 회원이 만남에 참고하실 수 있도록 해외 거주지와 연중 국내 방문 횟수를 '신앙고백'란에 함께 적어주세요.", text: "#2" }
        ]
      },
      {
        category: "idcards",
        isCollapsable: false,
        rejectionTemplates: [
          { value: "인증정보를 다시 등록해주세요.", text: "#1" },
          { value: "본인인증을 위해 신분증 이미지를 등록해주세요.\n신분증은 대한민국에서 발급된 주민등록증, 운전면허증만 인정됩니다.", text: "#2" },
          { value: "얼굴, 성명, 생년월일이 잘 보이도록 신분증을 촬영/등록해주세요.", text: "#3" },
          { value: "현재 크리스천 데이트의 가입가능 연령은 만 19~49세입니다.\n아쉽게도 귀하는 가입대상에 해당되지 않아 서비스 제공이 어려운 점 양해부탁드립니다.", text: "#4" },
          { value: "외국국적의 동포이신 경우는 <외국국적동포 국내거소신고증> 이미지를 등록해주세요.", text: "#5" },
          { value: "이미 이 개인정보로 등록된 회원정보가 존재합니다.\n혹시 로그인에 어려움을 겪고 계시다면 '고객센터'로 문의해주세요.", text: "#6" }
        ]
      },
      {
        category: "selfies",
        isCollapsable: false,
        rejectionTemplates: [
          { value: null, text: "반려사유 선택" },
          { value: "얼굴 전체가 잘 보이는 고화질 독사진만 인정됩니다.", text: "#1 독사진" },
          { value: "얼굴이 잘 안보이는 원거리 사진은 등록이 불가합니다.", text: "#2 원거리" },
          { value: "저화질/어두운 사진은 등록이 불가합니다.", text: "#3 저화질" },
          { value: "동일/유사 사진은 등록이 불가합니다.", text: "#4 동일" },
          { value: "본인 얼굴사진만 등록이 가능합니다.", text: "#5 본인" },
          { value: "흑백사진은 등록이 불가합니다.", text: "#6 흑백" },
          { value: "과도한 편집 사진은 등록이 불가합니다.", text: "#7 편집" },
          { value: "초근접 사진은 피해주세요.", text: "#8 초근접" },
          { value: "상대방에게 불쾌감을 줄 수 있는 사진은 등록이 불가합니다.", text: "#9 불쾌감" },
          { value: "개인정보 노출사진은 등록이 불가합니다.", text: "#10 개인정보" }
        ]
      },
      {
        category: "lifelogs",
        isCollapsable: false,
        rejectionTemplates: [
          { value: null, text: "반려사유 선택" },
          { value: "주의사항을 참고하여 컨텐츠를 변경해주세요.", text: "#1 반려" },
          { value: "내용과 함께 입력해주세요.", text: "#2 내용보충" }
        ]
      },
      {
        category: "work_interviews",
        isCollapsable: false,
        rejectionTemplates: [
          { value: "항목의 성격에 맞는 내용을 정확히 입력하세요.\n(회사명, 직업, 업무분야 등을 기재할 수 있습니다.)", text: "#1" },
          { value: "회사명/업무분야 중 최소 한가지 이상이 반드시 입력되어야 합니다.\n(회사명, 직업, 업무분야 등을 기재할 수 있습니다.)", text: "#2" },
          { value: "교회에서 했던 활동을 제외한 회사명/업무분야 중 최소 한가지 이상이 반드시 입력되어야 합니다.\n(회사명, 직업, 업무분야 등을 기재할 수 있습니다.)", text: "#3" },
          { value: "Work 항목의 내용과 '사회적 역할' 항목의 정보가 일치해야 합니다.", text: "#4" }
        ]
      },
      {
        category: "school_interviews",
        isCollapsable: true,
        rejectionTemplates: [
          { value: "항목의 성격에 맞는 내용을 정확히 입력하세요.\n(학교명, 전공 등을 기재할 수 있습니다.)", text: "#1" },
          { value: "출신학교/전공 중 최소 한가지 이상이 반드시 입력되어야 합니다.\n(고졸, 대학교명, 대학 전공명 등을 정확히 기재해주세요.)", text: "#2" },
          { value: "외국대학의 경우 full-name을 기재해주시기 바랍니다.", text: "#3" }
        ]
      },
      {
        category: "church_interviews",
        isCollapsable: true,
        rejectionTemplates: [
          { value: "항목의 성격에 맞는 내용을 정확히 입력하세요. (하단 예시 참조)", text: "#1" },
          { value: "항목의 성격에 맞는 내용을 두줄 이상 정확히 입력하세요. (하단 예시 참조)", text: "#2" },
          { value: "중복입력된 프로필 정보를 수정하세요.", text: "#3" },
          { value: "하단 예시를 참고하시되, 본인만의 이야기를 적어주세요.", text: "#4" }
        ]
      },
      {
        category: "place_interviews",
        isCollapsable: true,
        rejectionTemplates: [
          { value: "항목의 성격에 맞는 내용을 정확히 입력하세요. (하단 예시 참조)", text: "#1" },
          { value: "항목의 성격에 맞는 내용을 두줄 이상 정확히 입력하세요. (하단 예시 참조)", text: "#2" },
          { value: "중복입력된 프로필 정보를 수정하세요.", text: "#3" },
          { value: "하단 예시를 참고하시되, 본인만의 이야기를 적어주세요.", text: "#4" }
        ]
      },
      {
        category: "memory_interviews",
        isCollapsable: true,
        rejectionTemplates: [
          { value: "항목의 성격에 맞는 내용을 정확히 입력하세요. (하단 예시 참조)", text: "#1" },
          { value: "항목의 성격에 맞는 내용을 두줄 이상 정확히 입력하세요. (하단 예시 참조)", text: "#2" },
          { value: "중복입력된 프로필 정보를 수정하세요.", text: "#3" },
          { value: "하단 예시를 참고하시되, 본인만의 이야기를 적어주세요.", text: "#4" }
        ]
      },
      {
        category: "charm_interviews",
        isCollapsable: true,
        rejectionTemplates: [
          { value: "항목의 성격에 맞는 내용을 정확히 입력하세요. (하단 예시 참조)", text: "#1" },
          { value: "항목의 성격에 맞는 내용을 두줄 이상 정확히 입력하세요. (하단 예시 참조)", text: "#2" },
          { value: "중복입력된 프로필 정보를 수정하세요.", text: "#3" },
          { value: "하단 예시를 참고하시되, 본인만의 이야기를 적어주세요.", text: "#4" }
        ]
      },
      {
        category: "food_interviews",
        isCollapsable: true,
        rejectionTemplates: [
          { value: "항목의 성격에 맞는 내용을 정확히 입력하세요. (하단 예시 참조)", text: "#1" },
          { value: "항목의 성격에 맞는 내용을 두줄 이상 정확히 입력하세요. (하단 예시 참조)", text: "#2" },
          { value: "중복입력된 프로필 정보를 수정하세요.", text: "#3" },
          { value: "하단 예시를 참고하시되, 본인만의 이야기를 적어주세요.", text: "#4" }
        ]
      },
      {
        category: "prayer_interviews",
        isCollapsable: true,
        rejectionTemplates: [
          { value: "항목의 성격에 맞는 내용을 정확히 입력하세요. (하단 예시 참조)", text: "#1" },
          { value: "항목의 성격에 맞는 내용을 두줄 이상 정확히 입력하세요. (하단 예시 참조)", text: "#2" },
          { value: "중복입력된 프로필 정보를 수정하세요.", text: "#3" },
          { value: "하단 예시를 참고하시되, 본인만의 이야기를 적어주세요.", text: "#4" }
        ]
      },
      {
        category: "date_interviews",
        isCollapsable: true,
        rejectionTemplates: [
          { value: "항목의 성격에 맞는 내용을 정확히 입력하세요. (하단 예시 참조)", text: "#1" },
          { value: "항목의 성격에 맞는 내용을 두줄 이상 정확히 입력하세요. (하단 예시 참조)", text: "#2" },
          { value: "중복입력된 프로필 정보를 수정하세요.", text: "#3" },
          { value: "하단 예시를 참고하시되, 본인만의 이야기를 적어주세요.", text: "#4" }
        ]
      },
      {
        category: "vision_interview",
        isCollapsable: true,
        rejectionTemplates: [
          { value: "항목의 성격에 맞는 내용을 정확히 입력하세요. (하단 예시 참조)", text: "#1" },
          { value: "중복입력된 프로필 정보를 수정하세요.", text: "#2" },
          { value: "하단 예시를 참고하시되, 본인만의 이야기를 적어주세요.", text: "#3" }
        ]
      },
      {
        category: "episode_interviews",
        isCollapsable: true,
        rejectionTemplates: [
          { value: "항목의 성격에 맞는 내용을 정확히 입력하세요. (하단 예시 참조)", text: "#1" },
          { value: "항목의 성격에 맞는 내용을 두줄 이상 정확히 입력해주세요. (하단 예시 참조)", text: "#2" },
          { value: "중복입력된 프로필 정보를 수정하세요.", text: "#3" },
          { value: "하단 예시를 참고하시되, 본인만의 이야기를 적어주세요.", text: "#4" }
        ]
      },
      {
        category: "hope_interviews",
        isCollapsable: true,
        rejectionTemplates: [
          { value: "항목의 성격에 맞는 내용을 정확히 입력하세요. (하단 예시 참조)", text: "#1" },
          { value: "중복입력된 프로필 정보를 수정하세요.", text: "#2" },
          { value: "하단 예시를 참고하시되, 본인만의 이야기를 적어주세요.", text: "#3" }
        ]
      },
      {
        category: 'education',
        isCollapsable: false,
        rejectionTemplates: [
          { value: "졸업/재학/휴학/제적 증명서만 인정됩니다.", text: "#1" },
          { value: "대학원 학교인증 시에는 과정(석사/박사)을 구분하여 표기해주세요.", text: "#2" },
          { value: "모니터를 재촬영한 이미지는 정보확인이 어렵습니다.\n원본 이미지를 등록해주세요.", text: "#3" },
        ]
      },
      {
        category: 'workplace',
        isCollapsable: false,
        rejectionTemplates: [
          { value: "최근 1개월 이내 발급받은 재직증명서만 인정됩니다.", text: "#1" },
          { value: "최근 1개월 이내 발급받은 사업자등록증명원으로 제출해주시기 바랍니다.", text: "#2" },
          { value: "모니터를 재촬영한 이미지는 정보확인이 어렵습니다.\n원본 이미지를 등록해주세요.", text: "#3" },
        ]
      },
      {
        category: 'church',
        isCollapsable: false,
        rejectionTemplates: [
          { value: "유효한 인증자료가 아닙니다. 다시 등록해주세요.", text: "#1" },
          { value: "출석교회 정보와 인증 자료상의 정보가 일치하지 않습니다.\n정보가 일치하도록 수정해주세요.", text: "#2" },
          { value: "모니터를 재촬영한 이미지는 정보확인이 어렵습니다.\n원본 이미지를 등록해주세요.", text: "#3" },
        ]
      },
    ]
  },
  getters: {
    currentUser: (state) => {
      return state.currentUser
    },
    watermarkText: (state) => {
      return `<tspan x="0" dy="-1.1em">${ state.currentRequestIP }</tspan><tspan x="0" dy="1.1em">${ new Date(state.currentRequestTime * 1000).toLocaleString() }</tspan>`
    },
    getProfileNames: (state) => { return state.profileConfigs.map((profileConfig) => profileConfig.category) },
    getProfileConfig: (state) => (category: string) => { return state.profileConfigs.find((profileConfig) => profileConfig.category == category) || state.profileConfigs[0] },
    isCollapsableProfile: (state, getters) => (category: string) => { return getters.getProfileConfig(category).isCollapsable },
    getRejectionTemplates: (state, getters) => (category: string) => { return getters.getProfileConfig(category).rejectionTemplates },
    getRejectionTemplate: (state, getters) => (category: string, id: number) => { return getters.getRejectionTemplates(category)[id] || '' },
    photoUrl: (state) => (url: string) => {
      if (url.startsWith('http')) {
        return url
      } else {
        return state.baseURL + url
      }
    },
    googleProvider: () => {
      const provider = new firebase.auth.GoogleAuthProvider()
      provider.addScope('email')
      return provider
    },
    authProviders: (state) => {
      const user = firebase.auth().currentUser

      return user ? user.providerData : []
    },
    googleAuthData: (state, getters) => {
      return getters.authProviders.find((provider: any) => provider.providerId == 'google.com')
    },
    isProductionAPI: (state) => { return state.baseURL == "https://api.prod.christiandate.kr" },
    isProbationAdmin: (state, getters) => {
      return Array.isArray(getters.currentUser.roles) && getters.currentUser.roles.find((role: string) => role == 'probation')
    },
  },
  mutations: {
    currentUser (state, user) {
      state.currentUser = user
    },
    loading (state, isLoading) {
      if (isLoading) {
        state.refCount ++
        state.isLoading = true
      } else if (state.refCount > 0) {
        setTimeout(() => {
          state.refCount --
          state.isLoading = state.refCount > 0
        }, 300)
      }
    },
    setUsersReviewGenderFilter (state, value) {
      localStorage.setItem('usersReviewGenderFilter', value)
      state.usersReviewGenderFilter = value
    },
    setUsersReviewChurchFilter (state, value) {
      localStorage.setItem('usersReviewChurchFilter', value)
      state.usersReviewChurchFilter = value
    },
  },
  actions: {
    updateIPinfo ({ commit, state }) {
      axios.get(`/admin/v1/ip-info`)
        .then(({ data }) => {
          state.currentRequestIP = data.request_ip
          state.currentRequestTime = data.request_time
        })
    },
    signIn ({ commit }, payload) {
      return axios.post(`/admin/v1/sign-in`, payload.params)
    },
    signInWithToken ({ commit }, payload) {
      commit('loading', true)

      const request = firebase.auth().signInWithCustomToken(payload.token)

      request.finally(() => commit('loading', false))

      return request
    },
    signInWithGoogle ({ getters }) {
      return firebase.auth().signInWithPopup(getters.googleProvider)
    },
    linkWithGoogle ({ getters }) {
      const user = firebase.auth().currentUser

      return user!.linkWithPopup(getters.googleProvider)
    },
    unlinkWithGoogle () {
      const user = firebase.auth().currentUser

      return user!.unlink('google.com')
    },
    signedInNotify () {
      const user = firebase.auth().currentUser

      if (user) {
        return axios.post(`/admin/v1/signed-in-notify`, { uid: user.uid, email: user.email })
      }
    },
    signOut ({ commit }) {
      commit('currentUser', {})

      return firebase.auth().signOut()
    },
    getCurrentUser ({ commit, dispatch }) {
      const request = axios.get(`/admin/v1/users/me`)

      request.then(({ data }) => commit('currentUser', data.user)).catch(() => dispatch('signOut'))

      return request
    },
    getUsers ({ commit }, payload) {
      // NOTE: GET 요청은 params 로 한번 더 감싸야 QueryString 으로 변환
      return axios.get(`/admin/v1/users`, { params: payload.params })
    },
    searchUsers ({ commit }, payload) {
      return axios.get(`/admin/v1/users/search`, { params: payload.params })
    },
    getUser ({ commit }, payload) {
      return axios.get(`/admin/v1/users/${payload.id}`)
    },
    getUserStatistics ({ commit }, payload) {
      return axios.get(`/admin/v1/users/${payload.userId}/statistic`)
    },
    updateUserStatistics ({ commit }, payload) {
      return axios.put(`/admin/v1/users/${payload.userId}/statistic`)
    },
    updateUser ({ commit }, payload) {
      return axios.put(`/admin/v1/users/${payload.id}`, payload.params)
    },
    signOutUser ({ commit }, payload) {
      return axios.delete(`/admin/v1/users/${payload.id}`)
    },
    restoreSignOutUser ({ commit }, payload) {
      return axios.put(`/admin/v1/users/${payload.id}/restore`)
    },
    resetUserStatus ({ commit }, payload) {
      return axios.put(`/admin/v1/users/${payload.id}/profile/reset`)
    },
    reviewUser ({ commit }, payload) {
      return axios.put(`/admin/v1/users/${payload.id}/review`, payload.params)
    },
    reviewProfile ({ commit }, payload) {
      return axios.put(`/admin/v1/profiles/${payload.id}`, payload.params)
    },
    reviewPhoto ({ commit }, payload) {
      return axios.put(`/admin/v1/photos/${payload.id}`, payload.params)
    },
    reorderPhoto ({ commit }, payload) {
      return axios.put(`/v1/users/${payload.userId}/photos/reorder`, payload.params)
    },
    reorderPhotos ({ commit }, payload) {
      return axios.put(`/admin/v1/users/${payload.userId}/photos/reorder`, payload.params)
    },
    approveUnreviews ({ commit }, payload) {
      return axios.put(`/admin/v1/users/${payload.userId}/approve-profiles`)
    },
    updateProfile ({ commit }, payload) {
      return axios.post(`/v1/users/${payload.userId}/profiles`, payload.params)
    },
    removePhoto ({ commit }, payload) {
      return axios.delete(`/v1/users/${payload.userId}/photos/${payload.id}`)
    },
    deletePhoto ({ commit }, payload) {
      return axios.delete(`/admin/v1/photos/${payload.id}`)
    },
    reviewCertificate ({ commit }, payload) {
      return axios.put(`/admin/v1/certificates/${payload.id}`, payload.params)
    },
    deleteCertificate ({ commit }, payload) {
      return axios.delete(`/v1/users/${payload.userId}/certificates/${payload.id}`)
    },
    getWaitReviewProfiles ({ commit }, payload) {
      return axios.get(`/admin/v1/profiles/wait-reviews`, { params: payload.params })
    },
    getWaitReviewPhotos ({ commit }, payload) {
      return axios.get(`/admin/v1/photos/wait-reviews`, { params: payload.params })
    },
    getWaitReviewChurches ({ commit }, payload) {
      return axios.get(`/admin/v1/churches/wait-reviews`)
    },
    getWaitReviewCertificates ({ commit }, payload) {
      return axios.get(`/admin/v1/certificates/wait-reviews`, { params: payload.params })
    },
    getUserProfiles ({ commit }, payload) {
      return axios.get(`/admin/v1/users/${payload.userId}/profiles`, { params: payload.params })
    },
    getUserPhotos ({ commit }, payload) {
      return axios.get(`/admin/v1/users/${payload.userId}/photos`, { params: payload.params })
    },
    getUserCertificates ({ commit }, payload) {
      return axios.get(`/admin/v1/users/${payload.userId}/certificates`, { params: payload.params })
    },
    redirectUser ({ commit }, payload) {
      return axios.put(`/admin/v1/users/${payload.id}/redirect`, payload.params)
    },
    getUserDuplicates ({ commit }, payload) {
      return axios.get(`/admin/v1/users/${payload.id}/duplicates`)
    },
    getCouples ({ commit }, payload) {
      return axios.get(`/admin/v1/users/${payload.userId}/couples`, { params: payload.params })
    },
    createCouple ({ commit }, payload) {
      return axios.post(`/admin/v1/users/${payload.userId}/couples`, payload.params)
    },
    getCouple ({ commit }, payload) {
      return axios.get(`/admin/v1/couples/${payload.id}`)
    },
    updateCouple ({ commit }, payload) {
      return axios.put(`/admin/v1/couples/${payload.coupleId}`, payload.params)
    },
    getCoupleMessages ({ commit }, payload) {
      return axios.get(`/admin/v1/couples/messages`, { params: payload.params })
    },
    getUserMemos ({ commit }, payload) {
      return axios.get(`/admin/v1/users/${payload.userId}/memos`, { params: payload.params })
    },
    createUserMemo ({ commit }, payload) {
      return axios.post(`/admin/v1/users/${payload.userId}/memos`, payload.params)
    },
    deleteUserMemo ({ commit }, payload) {
      return axios.delete(`/admin/v1/users/${payload.userId}/memos/${payload.id}`)
    },
    removeUserMemo ({ commit }, payload) {
      return axios.delete(`/admin/v1/users/${payload.userId}/memos/${payload.id}/remove`)
    },
    getNotifications ({ commit }, payload) {
      return axios.get(`/v1/users/${payload.userId}/notifications`, { params: payload.params })
    },
    createNotification ({ commit }, payload) {
      return axios.post(`/admin/v1/notifications`, payload.params)
    },
    deleteNotification ({ commit }, payload) {
      return axios.delete(`/admin/v1/notifications/${payload.id}`)
    },
    getCoins ({ commit }, payload) {
      return axios.get(`/admin/v1/users/${payload.userId}/coins`, { params: payload.params })
    },
    createCoin ({ commit }, payload) {
      return axios.post(`/admin/v1/coins`, payload.params)
    },
    getNotices ({ commit }, payload) {
      return axios.get(`/admin/v1/notices`, { params: payload.params })
    },
    getNotice ({ commit }, payload) {
      return axios.get(`/admin/v1/notices/${payload.id}`)
    },
    createNotice ({ commit }, payload) {
      return axios.post(`/admin/v1/notices`, payload.params)
    },
    updateNotice ({ commit }, payload) {
      return axios.put(`/admin/v1/notices/${payload.id}`, payload.params)
    },
    deleteNotice ({ commit }, payload) {
      return axios.delete(`/admin/v1/notices/${payload.id}`)
    },
    getUserChurch ({ commit }, payload) {
      return axios.get(`/admin/v1/users/${payload.userId}/church`)
    },
    getChurch ({ commit }, payload) {
      return axios.get(`/admin/v1/churches/${payload.churchId}`)
    },
    getChurchUsers ({ commit }, payload) {
      if (payload.userId) {
        return axios.get(`/admin/v1/users/${payload.userId}/church-users`, { params: payload.params })
      } else if (payload.churchId) {
        return axios.get(`/admin/v1/churches/${payload.churchId}/users`, { params: payload.params })
      }
    },
    createChurch ({ commit }, payload) {
      return axios.post(`/admin/v1/churches`, payload.params)
    },
    updateChurch ({ commit }, payload) {
      return axios.put(`/admin/v1/churches/${payload.churchId}`, payload.params)
    },
    deleteChurch ({ commit }, payload) {
      return axios.delete(`/admin/v1/churches/${payload.id}`)
    },
    searchChurches ({ commit }, payload) {
      return axios.get(`/admin/v1/churches/search`, { params: payload.params })
    },
    getConsults ({ commit }) {
      return axios.get(`/admin/v1/consults`)
    },
    updateConsult ({ commit }, payload) {
      return axios.put(`/admin/v1/consults/${payload.id}`)
    },
    updateConsultLimits ({ commit }, payload) {
      return axios.put(`/admin/v1/consults/limits`, payload.params)
    },
    getBlockedUsers ({ commit }, payload) {
      return axios.get(`/admin/v1/users/blocked-users`, { params: payload.params })
    },
    getActivityLogs ({ commit }, payload) {
      return axios.get(`/admin/v1/users/${payload.userId}/activity-logs`, { params: payload.params })
    },
    getUserFilters ({ commit }, payload) {
      return axios.get(`/admin/v1/users/${payload.userId}/user-filters`, { params: payload.params })
    },
    getSelfiesAttentionMarkedUsers ({ commit }, payload) {
      return axios.get(`/admin/v1/users/selfies-attention-marked-users`, { params: payload.params })
    },
    getCouplePasses ({ commit }, payload) {
      return axios.get(`/admin/v1/users/${payload.userId}/couple-passes`, { params: payload.params })
    },
    deleteCouplePass ({ commit }, payload) {
      return axios.delete(`/admin/v1/users/${payload.userId}/couple-passes/${payload.id}`)
    },
    createPaylink ({ commit }, payload) {
      return axios.post('https://api.iamport.co/api/supplements/v1/link/payment', payload.params)
    },
    getDatasets ({ state }) {
      if (!state.isDatasetsRequestCalled) {
        state.isDatasetsRequestCalled = true
        state.datasetsRequest = axios.get('/v1/datasets')
      }

      return state.datasetsRequest
    }
  }
})

// Initialize Axios
axios.defaults.baseURL = baseURL

axios.interceptors.request.use(async (config) => {
  if (config.url && !config.url.startsWith('http')) {
    const user = firebase.auth().currentUser

    if (user) {
      const idToken = await user.getIdToken()
      if (config.headers) config.headers['Authorization'] = `Bearer ${idToken}`
      window.console.log('[axios.interceptors.request] idToken inserted')
    }
  }

  store.commit('loading', true)
  return config
}, (error) => {
  store.commit('loading', false)
  window.console.error(error)
  return Promise.reject(error)
})

axios.interceptors.response.use((response) => {
  store.commit('loading', false)
  window.console.log(response)
  return response
}, (error) => {
  store.commit('loading', false)
  window.console.error(error)
  return Promise.reject(error)
})

export default store
