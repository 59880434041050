
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import UserRedirectionButton from '@/components/UserRedirectionButton.vue'
import UserBadges from '@/components/UserBadges.vue'
import EmailContainer from '@/components/EmailContainer.vue'
import ProfileContainer from '@/components/ProfileContainer.vue'
import CertificateContainer from '@/components/CertificateContainer.vue'
import Photos from '@/components/Photos.vue'
import UserGradeGroup from '@/components/UserGradeGroup.vue'
import ChurchForm from '@/components/ChurchForm.vue'
import Devices from '@/components/Devices.vue'
import UserDuplicates from '@/components/UserDuplicates.vue'
import UserMemos from '@/components/UserMemos.vue'
import NotificationForm from '@/components/NotificationForm.vue'
import CoinForm from '@/components/CoinForm.vue'
import CoupleForm from '@/components/CoupleForm.vue'
import LogContainer from '@/components/logs/LogContainer.vue'
import ProfileLogs from '@/components/logs/Profiles.vue'
import PhotoLogs from '@/components/logs/Photos.vue'
import CertificateLogs from '@/components/logs/Certificates.vue'
import NotificationLogs from '@/components/logs/Notifications.vue'
import CoupleLogs from '@/components/logs/Couples.vue'
import CoinLogs from '@/components/logs/Coins.vue'
import Statistics from '@/components/logs/Statistics.vue'
import UserFilters from '@/components/logs/UserFilters.vue'
import ActivityLogs from '@/components/logs/ActivityLogs.vue'
import CouplePasses from '@/components/logs/CouplePasses.vue'

@Component({
  components: {
    UserRedirectionButton,
    UserBadges,
    EmailContainer,
    ProfileContainer,
    CertificateContainer,
    Photos,
    UserGradeGroup,
    ChurchForm,
    Devices,
    UserDuplicates,
    UserMemos,
    NotificationForm,
    CoinForm,
    LogContainer,
    ProfileLogs,
    PhotoLogs,
    CertificateLogs,
    NotificationLogs,
    CoupleLogs,
    CoinLogs,
    CoupleForm,
    Statistics,
    UserFilters,
    ActivityLogs,
    CouplePasses,
  },
  computed: {
    ...mapState(['isLoading', 'currentUser']),
    ...mapGetters({ isProbationAdmin: "isProbationAdmin" })
  },
})
export default class User extends Vue {
  user: any = {}
  church: any = {}

  created () {
    this.getUser()
  }

  get userId () { return this.$route.params.userId }

  @Watch('userId')
  onUserIdChanged () {
    this.user = {}
    this.church = {}

    this.getUser()
        .finally(() => this.getChurch())
  }

  getUser () {
    return this.$store.dispatch('getUser', { id: this.userId })
      .then(({ data }) => this.user = data.user)
  }

  getChurch () {
    if (this.user.profile.church_name) {
      this.$store.dispatch('getUserChurch', { userId: this.user.id })
        .then(({ data }) => this.church = data)
    }
  }

  reviewStatusDataset (status: string) {
    switch (status) {
      case 'on_review': return { title: '심사 롤백', variant: 'dark', comment: '( * 승인 대기 / 반려 대기가 모두 초기화 됩니다.)', show_back_popup: false }
      case 'wait_reject': return { title: '반려 대기', variant: 'danger', comment: '', show_back_popup: false }
      case 'wait_approve': return { title: '승인 대기', variant: 'success', comment: '', show_back_popup: false }
      case 'rejected': return { title: '반려 최종 확정', variant: 'warning', comment: '( * 적용 후 되돌릴 수 없습니다.)', show_back_popup: true }
      case 'approved': return { title: '승인 최종 확정', variant: 'primary', comment: '( * 적용 후 되돌릴 수 없습니다.)', show_back_popup: true }
      default: return null
    }
  }

  async confirmReviewUser (status: string) {
    const dataset = this.reviewStatusDataset(status)

    if (dataset && await this.$bvModal.msgBoxConfirm(`${dataset.title} 처리 하시겠습니까? ${dataset.comment}`, { okVariant: dataset.variant, autoFocusButton: 'ok' })) {
      this.reviewUser(this.userId, status)
    }
  }

  reviewUser (userId: string, status: string) {
    this.$store.dispatch('reviewUser', { id: userId, params: { status: status } })
      .then(async () => {
        const dataset = this.reviewStatusDataset(status)

        this.getUser()

        if (dataset) {
          this.$bvToast.toast(`[User#${userId}] 회원의 ${dataset.title} 처리가 완료되었습니다.`, {
            title: dataset.title,
            variant: dataset.variant
          })

          if (dataset.show_back_popup && await this.$bvModal.msgBoxConfirm('이 회원정보 페이지를 닫으시겠습니까?', { okVariant: 'primary', autoFocusButton: 'ok' })) {
            this.$router.back()
          }
        }
      })
      .catch(({ response }) => {
        this.$bvToast.toast(response.data.message || response, {
          title: '심사 오류',
          variant: 'danger'
        })
      })
  }

  async approveUnreviews () {
    if (await this.$bvModal.msgBoxConfirm('남은 심사항목을 전부 승인처리하시겠습니까? (* 급간 항목 제외)', { okVariant: 'success', autoFocusButton: 'ok' })) {
      this.$store.dispatch('approveUnreviews', { userId: this.userId })
        .finally(() => this.getUser())
    }
  }

  copyText (text: string) {
    this.$copyText(text)
  }

  async signOutUser () {
    if (await this.$bvModal.msgBoxConfirm('탈퇴 신청 하시겠습니까? (* 알림함에 탈퇴 신청 기록이 남습니다.)', { okVariant: 'dark', autoFocusButton: 'ok' })) {
      this.$store.dispatch('signOutUser', { id: this.userId })
        .then(() => this.getUser())
        .catch(({ response }) => {
          this.$bvToast.toast(response.data.message || response, {
            title: '탈퇴 신청 오류',
            variant: 'danger'
          })
        })
    }
  }

  async restoreSignOutUser () {
    if (await this.$bvModal.msgBoxConfirm('탈퇴 신청을 철회 하시겠습니까? (* 알림함에 탈퇴 신청 철회 기록이 남습니다.)', { okVariant: 'danger', autoFocusButton: 'ok' })) {
      this.$store.dispatch('restoreSignOutUser', { id: this.userId })
        .then(() => this.getUser())
        .catch(({ response }) => {
          this.$bvToast.toast(response.data.message || response, {
            title: '탈퇴 신청 철회 오류',
            variant: 'danger'
          })
        })
    }
  }

}
